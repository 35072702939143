define(['responsiveBuildYourOwnBundleProductList', 'accordionWidget', '$window', 'app'], (responsiveBuildYourOwnBundleProductList, accordion, $window, app) => {

  const responsiveBuildYourOwnBundle = () => {
    const component = {};
    const PRICE_PLACEHOLDER = 'PRICE';

    let _config = {
      selectors: {
        dialWrapper: '.responsiveBYOB_infoColumn .totalContent_dialWrapper',
        dial: '.responsiveBYOB_infoColumn .totalContent_dial',
        category: '.responsiveBYOB_productList .productCategory',
        itemCount: '.responsiveBYOB_infoColumn .totalContent_text_itemCount',
        progressBar: '.responsiveBYOB_infoColumn .totalProgressBar-bar',
        basketButton: '.responsiveBYOB_infoColumn .totalBasketButton',
        categoryList: '.responsiveBYOB_infoColumn .categoryList',
        totalWrapper: '.responsiveBYOB_infoColumn .totalWrapper',
        categoryListButton: '.responsiveBYOB_infoColumn .categoryList_button',
        productCategoryHeader: '.responsiveBYOB_productList .productCategoryHeader',
        productCategoryContent: '.responsiveBYOB_productList .productCategoryContent',
        totalPreDiscount: '.responsiveBYOB_infoColumn .totalContent_totalPreDiscount',
        totalPrice: '.responsiveBYOB_infoColumn .totalPrice',
        productPrice: '.productCard_productPrice',
        stickyHeader : '.westendHeader-sticky',
        BYOBHeader : '.responsiveBYOB_header',
        BYOBColumn : '.responsiveBYOB_contentColumn',
        BYOBHeaderButton : '.productCategoryHeader',
        BYOBPageHeader : 'header',
        BYOBResponsivePageHeader : '#nav',
        BYOBResponsiveSubmenu : '.responsiveSubMenu',
        BYOBPageHeaderStripBanner : '.stripBanner',
        BYOBPageHeaderBreadcrumbs :'.breadcrumbs',
        minimumSpend: '.minimumSpend'

      },
      dataAttributes: {
        maxProducts: 'data-max-products',
        unlimitedProducts: 'data-unlimited-products',
        expandAllCategories: 'data-expand-all-categories',
        summaryDisplayType: 'data-summary-display-type',
        showCategories: 'data-show-categories',
        dataPrice: 'data-price',
        minimumSpend: 'data-minimum'
      },
      classes: {
        categoryListButton: 'categoryList_button',
        bundleComplete: 'bundleComplete',
      },
      selectedProductsCount: 0,
      selectedProducts: [],
      accordions: [],
      initializedCategories: [],
      showCategories: true,
      radioOptionChanged: false,
      hasSectionMiddleRail: false,
    };

    const _init = (element) => {
      component.element = element;

      component.elements = {
        dialWrapper: component.element.querySelector(component.config.selectors.dialWrapper),
        dial: component.element.querySelector(component.config.selectors.dial),
        categories: [...component.element.querySelectorAll(component.config.selectors.category)],
        itemCounts: [...component.element.querySelectorAll(component.config.selectors.itemCount)],
        progressBar: component.element.querySelector(component.config.selectors.progressBar),
        totalBasketButtons: [...component.element.querySelectorAll(component.config.selectors.basketButton)],
        categoryList: component.element.querySelector(component.config.selectors.categoryList),
        totalWrapper: component.element.querySelector(component.config.selectors.totalWrapper),
        totals: [...component.element.querySelectorAll(component.config.selectors.totalPreDiscount)],
        totalPrice: component.element.querySelector(component.config.selectors.totalPrice),
        priceFormat: component.getPriceFormat(component.element.querySelector(component.config.selectors.productPrice)),
        minimumSpend: component.element.querySelector(component.config.selectors.minimumSpend)
      };

      const maxProducts = parseInt(component.element.getAttribute(component.config.dataAttributes.maxProducts));

      component.config.maxProducts = (maxProducts > 0) ? maxProducts : 0;
      component.config.minimumSpend = null;
      component.config.minimumSpendEnabled = false;
      component.config.unlimitedProducts = component.config.maxProducts === 0 ? 'true' : component.element.getAttribute(component.config.dataAttributes.unlimitedProducts);
      component.config.expandAllCategories = component.element.getAttribute(component.config.dataAttributes.expandAllCategories) !== 'false';
      component.config.bundleTotal = component.getTotal();
      component.config.type = component.getType();
      component.config.showCategories = component.element.getAttribute(component.config.dataAttributes.showCategories) !== 'false';
      component.initCategories();
      component.updateProgress();
      component.initTracking();

      if(component.config.type === 'dial') {
        component.makeDial();
        $window.addEventListener('optimizedResize', component.makeDial, false);
        _throttle('resize', 'optimizedResize');
      }
    };

    const _getType = () => {
      return (component.config.unlimitedProducts !== 'false' ||
        (component.element.getAttribute(component.config.dataAttributes.summaryDisplayType) === 'receipt')) ? 'receipt' : 'dial';
    };

    const _getTotal = () => {
      if(component.config.selectedProducts && component.config.selectedProducts.length > 0) {
        let total = 0;
        component.config.selectedProducts.forEach((product) => {
          total += parseFloat(product.total.toFixed(2));
        });
        return total;
      }
      return 0;
    };

    const _initCategories = () => {
      component.initAccordions();

      component.config.initializedCategories = component.elements.categories.map((el) => {
        const rBYOBProdList = new responsiveBuildYourOwnBundleProductList();
        rBYOBProdList.init(component, el, component.config.maxProducts);
        return rBYOBProdList;
      });

      if(component.config.showCategories) {
        component.config.initializedCategories.forEach(cat => {
          component.elements.categoryList.innerHTML += `<li><button type="button" class="${component.config.classes.categoryListButton}">${cat.getTitle()}</button></li>`;
        });

        const categoryJumpButtons = [...component.element.querySelectorAll(component.config.selectors.categoryListButton)];
        categoryJumpButtons.map((el, i) => {
          el.addEventListener('click', () => {
            component.categoryScroll(i);
          });
        });
      }
    };

    const _initTracking = () => {
      component.elements.totalBasketButtons.map((button) => {
        button.addEventListener('click', () => {
          let skuList = '';

          component.config.selectedProducts.map(product => {
            skuList += `${product.sku}:${product.quantity},`;
          });
          skuList = skuList.slice(0, -1);

          app.publish('tracking/record', 'Build Your Own Bundle', 'Add to basket', skuList);
          app.publish('columbo/track', 'Build Your Own Bundle', 'Add to basket', skuList);
        });
      });
    };

    const _getOffsetTop = (element) => {
      let offsetTop = 0;
      while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    }

    const _categoryScroll = (buttonItem) => {

      component.config.accordions[buttonItem].open();
      component.config.accordions[buttonItem].title.focus();

      let BYOBHeaderButtons = document.querySelectorAll(component.config.selectors.BYOBHeaderButton);
      let BYOBResponsivePageHeader = document.querySelector(component.config.selectors.BYOBResponsivePageHeader);
      let BYOBResponsiveSubmenu = document.querySelector(component.config.selectors.BYOBResponsiveSubmenu);
      let BYOBPageHeaderStripBanner = document.querySelector(component.config.selectors.BYOBPageHeaderStripBanner);
      let BYOBPageHeaderBreadcrumbs = document.querySelector(component.config.selectors.BYOBPageHeaderBreadcrumbs);

      let BYOBResponsivePageHeader_height = BYOBResponsivePageHeader ? BYOBResponsivePageHeader.offsetHeight : 0;
      let distanceToMove = 0;

      distanceToMove += BYOBHeaderButtons[buttonItem] ? BYOBHeaderButtons[buttonItem].offsetTop : 0;
      distanceToMove += BYOBResponsivePageHeader_height;
      distanceToMove += BYOBPageHeaderStripBanner ? BYOBPageHeaderStripBanner.offsetHeight : 0;
      distanceToMove += BYOBPageHeaderBreadcrumbs ? BYOBPageHeaderBreadcrumbs.offsetHeight : 0;
      distanceToMove += BYOBResponsiveSubmenu ? BYOBResponsiveSubmenu.offsetHeight : 0;

      let hasFixedHeader = !!document.querySelector(component.config.selectors.stickyHeader);

      if (hasFixedHeader !== true) distanceToMove -= BYOBResponsivePageHeader_height;

      window.scroll({
        left: 0,
        top: distanceToMove,
        behavior: 'smooth'
      })
    }

    const _initAccordions = () => {
      const openAccordions = component.config.expandAllCategories;
      const accordionConfig = {
        isOpen: openAccordions,
        selectors: {
          title: component.config.selectors.productCategoryHeader,
          content: component.config.selectors.productCategoryContent,
        },
        offset: 1
      };

      component.elements.categories.forEach((accordionEle, index) => {
        const accordionConfigCopy = Object.assign({}, accordionConfig);
        if(index === 0) accordionConfigCopy.isOpen = true;

        const catAccordion = new accordion().init(accordionEle, accordionConfigCopy);
        component.config.accordions.push(catAccordion);
      });
    };

    const _addProduct = (productSku, quantity, price) => {
      const check = component.config.unlimitedProducts === 'true' || (component.config.selectedProductsCount + quantity) <= component.config.maxProducts;
      if(!check) return;

      const productInArray = component.config.selectedProducts.filter(item => item.sku === productSku);
      let returnQuantity = 0;

      if(productInArray.length > 0) {
        //there should only ever be one match
        const productIndex = component.config.selectedProducts.indexOf(productInArray[0]);
        const newProduct = {
          sku: component.config.selectedProducts[productIndex].sku,
          quantity: component.config.selectedProducts[productIndex].quantity + quantity,
          total: component.config.selectedProducts[productIndex].total + quantity * price,
        };

        component.config.selectedProducts[productIndex] = newProduct;
        returnQuantity = newProduct.quantity;
      } else {
        component.config.selectedProducts.push({
          sku: productSku,
          quantity: quantity,
          total: quantity * price,
        });
        returnQuantity = quantity;
      }

      component.updateProgress();
      return {
        quantity: returnQuantity,
        totalQuantity: component.config.selectedProductsCount
      };
    };

    const _removeProduct = (productSku, quantity, price) => {
      const productInArray = component.config.selectedProducts.filter(item => item.sku === productSku);
      let returnQuantity = 0;

      if(productInArray.length > 0) {
        //there should only ever be one match, if no match then product was never added
        const productIndex = component.config.selectedProducts.indexOf(productInArray[0]);

        if(quantity !== 'all') {
          const newProduct = {
            sku: component.config.selectedProducts[productIndex].sku,
            quantity: component.config.selectedProducts[productIndex].quantity - quantity,
            total: component.config.selectedProducts[productIndex].total - quantity * price,
          };

          component.config.selectedProducts[productIndex] = newProduct;
          returnQuantity = newProduct.quantity;
          component.updateProgress();
          return {
            quantity: returnQuantity,
          }
        } else {
          const removedProduct =  component.config.selectedProducts[productIndex];
          component.config.selectedProducts.splice(productIndex, 1);
          component.updateProgress();
          return {
            removedQuantity: removedProduct.quantity,
          };
        }
      }
    };

    const _getProductQuantity = (sku) => {
      return component.config.selectedProducts.filter(item => item.sku === sku)[0].quantity;
    };

    const _getDialColors = () => {
      return {
        background: window.getComputedStyle(component.elements.dial).getPropertyValue('border-color'),
        foreground: window.getComputedStyle(component.elements.dial).getPropertyValue('color')
      };
    };

    const _updateProgress = () => {
      component.updateTotalItems();
      component.updateProgressBar();
      component.checkForComplete();
      component.updateBuyLink();
      const total = component.getTotal();

      component.elements.itemCounts.map((el) => {
        el.innerText = component.config.selectedProductsCount;
      });

      component.elements.totals.map(el => {
        const priceFormat = component.elements.priceFormat;
        const toReplace = priceFormat.substr([...priceFormat].indexOf('0'), priceFormat.replace(/[^0-9.]/g, "").length);

        el.innerText = component.elements.priceFormat.replace(toReplace, total.toFixed(2));
      });

      component.makeDial();
    };

    const _updateProgressBar = () => {
      component.elements.progressBar.style.width = `${(component.config.selectedProductsCount / component.config.maxProducts * 100).toFixed(1)}%`;
    };

    const _updateTotalItems = () => {
      let runningTotal = 0;

      component.config.selectedProducts.forEach(i => {
        runningTotal += i.quantity;
      });

      component.config.selectedProductsCount = runningTotal;
    };

    const _getRemainingLimit = () => {
      component.updateTotalItems();
      return component.config.maxProducts - component.config.selectedProductsCount;
    };

    const _checkForComplete = () => {
      const total = component.getTotal();

      if(component.config.unlimitedProducts !== 'true' && component.config.selectedProductsCount === component.config.maxProducts && !component.config.minimumSpendEnabled ||
        component.config.unlimitedProducts === 'true' && component.config.selectedProductsCount > 0 && !component.config.minimumSpendEnabled) {
        if (component.config.unlimitedProducts !== 'true') {
          component.config.initializedCategories.forEach((cat) => {
            cat.setBundleCategoryStatus('limitReached');
          });
        }
        if(!component.config.radioOptionChanged) {
          component.elements.totalWrapper.classList.add(component.config.classes.bundleComplete);
        }
        component.config.radioOptionChanged = false;
      } else if (component.config.minimumSpendEnabled && component.calculateMinimumSpend(total)) {
        component.elements.totalWrapper.classList.add(component.config.classes.bundleComplete);
      } else {
        if (component.config.unlimitedProducts !== 'true') {
          component.config.initializedCategories.forEach((cat) => {
            cat.setBundleCategoryStatus('limitNotReached');
          });
        }
        if(!component.config.radioOptionChanged) {
          component.elements.totalWrapper.classList.remove(component.config.classes.bundleComplete);
        }
        component.config.radioOptionChanged = false;
      }
    };

    const _radioOptionChanged = () => {
      component.config.radioOptionChanged = true;
    };

    const _updateBuyLink = () => {
      let buyLink = '/my.basket?buylist=';

      component.config.selectedProducts.map(product => {
        buyLink += `${product.sku}:${product.quantity},`;
      });

      buyLink = buyLink.slice(0, -1);

      component.elements.totalBasketButtons.map(el => {
        el.href = buyLink;
      });
    };

    const _makeDial = () => {
      if(!component.elements.dialWrapper) return;

      const centerX = component.elements.dialWrapper.offsetWidth / 2;
      const centerY = component.elements.dialWrapper.offsetWidth / 2;
      const radius = 95;
      const start = 0.8 * Math.PI;
      const end = 2.2 * Math.PI;

      const ctx = component.elements.dial.getContext('2d');
      const dialColor = component.getDialColors();

      ctx.clearRect(0, 0, component.elements.dialWrapper.offsetWidth, component.elements.dialWrapper.offsetHeight);

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, start, end, false);
      ctx.lineWidth = 28;
      ctx.strokeStyle = dialColor.background;
      ctx.stroke();

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, start,
        start + (component.config.selectedProductsCount * ((end - start) / component.config.maxProducts)), false);
      ctx.lineWidth = 26;
      ctx.strokeStyle = dialColor.foreground;
      ctx.stroke();
    };

    const _throttle = function(type, name, comp) {
      comp = comp || $window;
      let running = false;
      const func = function() {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function() {
          comp.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      comp.addEventListener(type, func);
    };

    const _getPriceFormat = (priceDom) => priceDom ? priceDom.innerText.replace(/[0-9,.]/g, '0') : '0';

    component.config = _config;

    component.initAccordions = _initAccordions;
    component.initCategories = _initCategories;
    component.initTracking = _initTracking;
    component.categoryScroll = _categoryScroll;
    component.getType = _getType;
    component.getTotal = _getTotal;
    component.addProduct = _addProduct;
    component.removeProduct = _removeProduct;
    component.getDialColors = _getDialColors;
    component.updateTotalItems = _updateTotalItems;
    component.getRemainingLimit = _getRemainingLimit;
    component.getProductQuantity = _getProductQuantity;
    component.updateProgress = _updateProgress;
    component.updateProgressBar = _updateProgressBar;
    component.checkForComplete = _checkForComplete;
    component.radioOptionChanged = _radioOptionChanged;
    component.updateBuyLink = _updateBuyLink;
    component.makeDial = _makeDial;
    component.getPriceFormat = _getPriceFormat;
    component._getOffsetTop = _getOffsetTop;
    component.init = _init;

    return component;
  };

  return responsiveBuildYourOwnBundle;

});
